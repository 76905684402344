export const buyLimit = [
  {
    label: '新用户',
    value: '1'
  },
  {
    label: '老用户',
    value: '2'
  },
  {
    label: '不限制',
    value: '0'
  }
]
