<template>
  <el-form-item :prop="isHandsel ? '' : 'handselList2'" class="handsel" :label="label">
    <div class="handsel-title">
      <div>商品名称</div>
      <div>售价</div>
      <div>{{ isHandsel ? "赠送次数" : "购买次数" }}</div>
      <div v-if="!isHandsel">每次平均价格</div>
      <div>操作</div>
    </div>
    <div class="handsel-list">
      <template :key="item" v-for="item in handselList">
        <div v-if="item.paraIdList.length > 0" class="handsel-item">
          <div class="name" v-if="item.serveId">{{ item.serveName }}</div>
          <div class="name" v-if="item.goodName">{{ item.goodName }}</div>
          <div class="name">¥{{ item.paraIdList[0].salePrice }}</div>
          <div :style="{ flex: isHandsel ? 2 : 3 }" class="format-list">
            <template :key="ele" v-for="ele in item.paraIdList">
              <div class="format-item">
                <template v-if="category == 'serve'">
                  <div v-if="currentCardKind != '4'">
                    <el-input v-model="ele.Qty">
                      <template #suffix>| 次</template>
                    </el-input>
                  </div>
                  <div v-if="currentCardKind == '4'">
                    <el-input disabled placeholder="不限次数" />
                  </div>
                </template>
                <template v-else>
                  <div>
                    <el-input v-model="ele.Qty">
                      <template #suffix>| 次</template>
                    </el-input>
                  </div>
                </template>
                <div v-if="!isHandsel">
                  <template v-if="ele.averagePrice">
                    ¥ {{ ele.averagePrice.toFixed(2) }}
                  </template>
                  <template v-else> - </template>
                </div>
                <div class="opt">
                  <el-button
                    v-if="ele.isUpdate && ele.serveId"
                    @click="handleHandelUpdateClick(ele, 'serve')"
                    size="small"
                    plain
                  >
                    修改</el-button
                  >

                  <el-button
                    v-if="item.serveId"
                    @click="handleHandelRmoveClick(item, ele, 'serve')"
                    size="small"
                    plain
                  >
                    删除</el-button
                  >
                  <el-button
                    size="small"
                    plain
                    v-if="!ele.isUpdate && ele.serveId"
                    class="hide"
                    >修改</el-button
                  >
                  <el-button
                    v-if="ele.isUpdate && ele.goodId"
                    @click="handleHandelUpdateClick(ele, 'goods')"
                    size="small"
                    plain
                  >
                    修改</el-button
                  >

                  <el-button
                    v-if="item.goodId"
                    @click="handleHandelRmoveClick(item, ele, 'goods')"
                    size="small"
                    plain
                  >
                    删除
                  </el-button>
                  <el-button
                    size="small"
                    plain
                    v-if="!ele.isUpdate && ele.goodId"
                    class="hide"
                    >修改</el-button
                  >
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
  </el-form-item>
</template>

<script lang="js">
import { COMPANY_ID } from '@/common/constant'
import store from '@/store'
import emitter from '@/utils/eventbus'
import { emit } from 'process'
import { defineComponent, reactive, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  props: {
    label: {
      type: String,
      default: ""
    },
    handselList: {
      type: Array,
      default() {
        return []
      }
    },
    category: {
      type: String,
      default: 'serve'
    },
    isHandsel: {
      type: Boolean,
      default: false
    }
  },
  components: {

  },
  emits: ['handleSiteHandselClick'],
  setup(props, { emit }) {
    const route = useRoute()
    const currentCardId = computed(() => route.query.cardId)
    const handleSiteHandselClick = () => {
      emit('handleSiteHandselClick')
    }

    const handleHandelUpdateClick = (item, type) => {
      // console.log(item);
      if (props.category == 'serve') {
        let obj = {
          companyId: COMPANY_ID,
          cardId: currentCardId.value,
          serveId: item.serveId,
          qty: item.Qty,
          avaragePrice: item.averagePrice
        }
        store.dispatch('card/updateCardServeInfoAction', obj)
      } else {
        let obj = {
          companyId: COMPANY_ID,
          cardId: currentCardId.value,
          qty: item.Qty,
          avaragePrice: item.averagePrice,
          giftKind: item.serveId ? 1 : 2,
          relateId: item.serveId ? item.serveId : item.goodId
        }
        store.dispatch('card/updateCardHandselInfoAction', obj)
      }
    }

    const handleHandelRmoveClick = (item, ele, type) => {
      if (props.category == 'serve') {
        if (ele.isUpdate) {
          store.dispatch('card/removeCardServeBindAction', [item.serveId, currentCardId.value, COMPANY_ID, ele.paraId])
        }
        emitter.emit('updateCardServeFormServeRmove', { serveId: item.serveId, paraId: ele.paraId })

      } else {
        if (type == 'serve') {
          if (ele.isUpdate) {
            store.dispatch('card/removeCardhandselBindAction', [currentCardId.value, 1, item.serveId, ele.paraId])
          }
          emitter.emit('updateCardServeFormHandselServeRmove', { serveId: item.serveId, paraId: ele.paraId })
        } else {
          if (ele.isUpdate) {
            store.dispatch('card/removeCardhandselBindAction', [currentCardId.value, 2, item.goodId, ele.paraId])
          }
          emitter.emit('updateCardServeFormHandselGoodsRmove', { goodId: item.goodId, paraId: ele.paraId })
        }
      }
    }

    const currentCardKind = ref('')
    emitter.on('changeUpdateCardCardKind', kindId => {
      currentCardKind.value = kindId
    })

    return {
      currentCardKind,
      handleSiteHandselClick,
      handleHandelRmoveClick,
      currentCardId,
      COMPANY_ID,
      handleHandelUpdateClick
    }
  }
})
</script>

<style scoped lang="less">
.handsel {
  .handsel-title {
    display: flex;
    max-width: 806px;
    font-size: 12px;

    & > div {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .handsel-list {
    max-width: 806px;

    .handsel-item {
      display: flex;
      align-items: center;
      padding-bottom: 2px;
      border-bottom: 1px solid var(--borderColor);
      padding: 4px 10px;

      .name {
        flex: 1;
        font-size: 12px;
        text-align: center;
      }

      .format-list {
        flex: 3;

        .format-item {
          display: flex;
          justify-content: space-around;
          margin: 8px 0;

          &:deep(.el-input) {
            max-width: 140px;
          }

          & > div {
            flex: 1;
            display: flex;
            justify-content: center;
          }

          .opt {
            display: flex;
            flex-wrap: wrap;

            .hide {
              opacity: 0;
            }
          }
        }
      }
    }
  }

  .handsel-title {
    color: rgba(80, 80, 80, 1);
    background-color: rgba(239, 239, 239, 1);
    font-size: 12px;
  }
}
</style>
