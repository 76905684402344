<template>
  <div class="add-form">
    <el-tabs v-model="activeName" class="tabs" @tab-click="handleClick">
      <el-tab-pane disabled name="baseInfo" label="第一步：编辑基本信息">
        <base-info></base-info>
      </el-tab-pane>
      <el-tab-pane disabled name="editDetail" label="第二步：编辑卡详情">
        <card-detail></card-detail>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onUnmounted } from 'vue'
import BaseInfo from './cpns/base-info/base-info.vue'
import CardDetail from './cpns/card-detail/card-detail.vue'
import emitter from '@/utils/eventbus'
export default defineComponent({
  props: {

  },
  components: {
    BaseInfo,
    CardDetail
  },
  setup() {
    const activeName = ref('baseInfo')
    emitter.on('changeUpdateCardNextClick', () => {
      activeName.value = 'editDetail'
    })
    emitter.on('changeUpdateCardPreviousClick', () => {
      activeName.value = 'baseInfo'
    })

    onUnmounted(() => {
      emitter.off('changeUpdateCardNextClick')
      emitter.off('changeUpdateCardPreviousClick')
    })

    return {
      activeName
    }

  }
})
</script>

<style scoped lang="less">
</style>




