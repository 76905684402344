<template>
  <div class="opt-serve">
    <div class="serve-name">{{ serveName }}</div>
    <div class="price">¥{{ ruleForm.salePrice }}</div>
    <div class="num">
      <el-input-number size="small" v-model="ruleForm.Qty" :min="1" :max="10" />
    </div>
    <div>
      <el-button size="small" plain class="remove" @click="removeServeClick(ruleForm.serveId, ruleForm.paraId)"
        type="danger">删除
      </el-button>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {
    ruleForm: {
      type: Object,
      default() {
        return {}
      }
    },
    serveName: {
      type: String,
      default: ''
    }
  },
  components: {

  },
  emits: ['removeServeClick'],
  setup(props, { emit }) {

    const removeServeClick = (serveId, paraId) => {
      emit('removeServeClick', serveId, paraId)
    }

    return {
      removeServeClick
    }

  }
})
</script>

<style scoped lang="less">
.opt-serve {
  padding: 0 10px;
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 12px;
  &:deep(.el-input-number) {
    width: 100% !important;
  }

  div {
    flex: 1;
    padding-left: 0px;

  }
  .price{
    color: #ff6600;
  }
  .remove {
    cursor: pointer;
  }
}
</style>




