<template>
  <div class="mobile-preview">
    <div class="top">
      <div class="left">
        <div class="top-info">
          <img src="~assets/img/card/mobile-top.png" alt />
        </div>

        <div v-if="String(contentHtml).length <=11" class="tips">
          <div>基本信息区</div>
          <div>固定样式，显示商品主图、价格等信息</div>
        </div>
        <div class="content">
          <el-scrollbar class="scrollbar" :height="{ height: String(contentHtml).length <=11 ? '506px' : '170px' }">
            <div v-html="contentHtml" :style="{ height: String(contentHtml).length >11? '576px' : '170px' }"
              class="goods-detail"></div>
          </el-scrollbar>
        </div>
      </div>
      <div class="right">
        <div class="right-top">
          <div class="title">
            <span>分享文案</span>
          </div>
          <div class="el-input">
            <el-input v-model="writingValue" show-word-limit :rows="2" type="textarea" :maxlength="32" />
          </div>
        </div>
        <div class="right-bottom">
          <div class="title">
            <span>商品详情</span>
          </div>
          <page-md-editor class="md-editor" @onTextChanged="onTextChanged" @onHtmlChanged="onHtmlChanged">
          </page-md-editor>
        </div>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="bottom">
      <div class="left">
        <!-- <el-button @click="handleLastClick" plain>上一步</el-button> -->
        <el-button @click="handleSaveClick" type="primary">保存</el-button>
      </div>
      <div class="right">
      </div>
    </div>
  </div>
</template>

<script lang="js">
import emitter from '@/utils/eventbus';
import { emit } from 'process'
import { defineComponent, reactive, ref } from 'vue'
import PageMdEditor from '../page-md-editor/page-md-editor.vue'
export default defineComponent({
  props: {
    copywritingValue: {
      type: String,
      default: ""
    }
  },
  components: {
    PageMdEditor
  },
  computed: {
    writingValue: {
      set(copywritingValue) {
        this.$emit("update:copywritingValue", copywritingValue);
      },
      get() {
        return this.copywritingValue;
      }
    }
  },
  emits: ['handleLastClick', 'update:copywritingValue', 'handleSaveClick', 'htmlChang', 'onTextChanged'],
  setup(peops, { emit }) {

    const contentText = ref('')
    const contentHtml = ref('')
    const onHtmlChanged = (value) => {
      contentHtml.value = value
      emit('htmlChang', value)
    }

    const handleSaveClick = () => {
      emit('handleSaveClick')
    }


    const handleLastClick = () => {
      emit('handleLastClick')
    }

    const onTextChanged = (value) => {
      contentText.value = value
      emit('onTextChanged', value)
    }



    return {
      onTextChanged,
      contentText,
      onHtmlChanged,
      handleLastClick,
      handleSaveClick,
      contentHtml

    }

  }
})
</script>

<style scoped lang="less">
.mobile-preview {
  padding: 36px 6px 6px 6px;
  min-width: 980px;

  &:deep(.el-input .el-input__count) {
    height: auto !important;

  }

  .el-scrollbar {
    width: 100%;
  }

  .top {
    display: flex;

    .left,
    .right {
      min-width: 340px;
      max-width: 340px;
      height: 660px;
    }

    .left {
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
      margin-right: 50px;
      background-color: rgb(239, 239, 239);

      .content {
        padding: 8px;
        width: 340px;

        .scrollbar {
          border: 1px solid rgba(255, 141, 26, 0.5);
          background-color: rgba(255, 255, 255, 1);
        }

        .goods-detail {
          width: 100%;
          height: 174px;
          word-wrap: break-word;
          word-break: normal;

          img {
            width: 100%;
          }

          &:deep(p) {
            img {
              width: 100%;
            }
          }

          &:deep(figure) {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 94%;
              margin: 4px 0;
            }
          }
        }
      }

      .top-info {
        height: 66px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .tips {
        height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        div {
          text-align: center;
          color: rgba(80, 80, 80, 1);
        }

        &>div:nth-child(1) {
          font-size: 16px;
          margin-bottom: 16px;
        }

        &>div:nth-child(2) {
          font-size: 14px;
        }
      }
    }

    .right {
      .right-top {
        height: 170px;

        .el-input {
          padding: 10px;
          background-color: rgba(239, 239, 239, 1);
        }
      }

      .right-top,
      .right-bottom {
        .title {
          width: 100%;
          display: flex;
          justify-content: space-between;
          position: relative;
          margin-bottom: 20px;
          padding-left: 14px;
          font-size: 14px;
          box-sizing: border-box;

          &::before {
            content: "";
            position: absolute;
            width: 6px;
            height: 22px;
            color: rgba(80, 80, 80, 1);
            background-color: rgba(42, 130, 228, 1);
            left: 0;
            top: -2px;
          }
        }
      }

      .right-bottom {
        height: 490px;
        // overflow-y: hidden;

        .md-editor {
          width: 100%;
          padding: 0 10px 10px 10px;
          background-color: rgba(239, 239, 239, 1);
          height: 93%;

          &:deep(.md) {
            height: 432px;
          }

          &:deep(.md-toolbar-wrapper) {
            .md-toolbar-item {
              padding: 0 0;
            }

            .md-toolbar {
              max-width: 340px;
              min-width: 310px;
              flex-wrap: wrap;
              height: auto;
            }

            .md-toolbar-left {
              flex-wrap: wrap;
            }

            .md-toolbar-right {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  .bottom {
    width: 720px;
    display: flex;
    justify-content: center;

    &:deep(.el-button) {
      width: 156px;
      height: 48px;
    }
  }
}
</style>




