<template>
  <div class="card-add">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="card-add-content">
      <add-form></add-form>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import AddForm from './cpns/add-forn/add-fom.vue'
export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    AddForm
  },
  setup() {


    return {
      breadcrumbList
    }

  }
})
</script>

<style scoped lang="less">
.card-add {
  .card-add-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
    min-width: 980px;
  }
}
</style>




