<template>
  <div class="card-detail">
    <mobile-preview @htmlChang="htmlChang" @handleSaveClick="handleSaveClick"
      v-model:copywritingValue="copywritingValue" @handleLastClick="handleLastClick"></mobile-preview>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, onUnmounted } from 'vue'
import MobilePreview from '@/components/mobile-preview/mobile-preview.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import emitter from '@/utils/eventbus'
export default defineComponent({
  props: {

  },
  components: {
    MobilePreview
  },
  setup() {
    const store = useStore()
    const copywritingValue = ref('')
    const htmlValue = ref('')
    const handleSaveClick = () => {
      let obj = {
        isUpdate: true,
        cardId: -1,
        companyId: COMPANY_ID,
        cardInfo: htmlValue.value,
        shareWords: copywritingValue.value
      }
      store.dispatch('card/setCardShareInfoAction', obj)
      emitter.emit('emptyPageMdEditorValue')
      copywritingValue.value = ''
    }

    const htmlChang = (value) => {
      htmlValue.value = value
    }

    const handleLastClick = () => {
      emitter.emit('changeUpdateCardPreviousClick')
    }

    emitter.on('changeUpdateCardShareWords', shareWords => {
      copywritingValue.value = shareWords
    })

    onUnmounted(() => {
      emitter.off('changeUpdateCardShareWords')
    })

    return {
      handleLastClick,
      htmlChang,
      htmlValue,
      copywritingValue,
      handleSaveClick,
      COMPANY_ID

    }

  }
})
</script>

<style scoped lang="less">
.card-detail {}
</style>
