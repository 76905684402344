<template>
  <div class="add-staff-dialog">
    <page-dialog
      :width="56"
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
      title="添加服务"
      @handleSaveClick="handleSaveClick"
      :isShowFooter="true"
    >
      <div class="top-form">
        <el-select
          clearable
          @change="categoryChange"
          v-model="queryInfo.kind"
          class="serveCategory"
          placeholder="请选择分类"
          size="small"
        >
          <el-option
            v-for="item in serveCategoryList"
            :key="item.kindId"
            :label="item.kindName"
            :value="item.kindId"
          >
          </el-option>
        </el-select>
        <el-input
          size="small"
          clearable
          v-model="queryInfo.keyword"
          placeholder="输入关键词"
          @clear="handleSearchClick"
        />
        <el-button size="small" @click="handleSearchClick" type="primary" plain
          >搜索</el-button
        >
      </div>

      <div class="content">
        <div class="left-item">
          <div class="titls">
            <div>服务</div>
            <div>价格</div>
            <div>时长</div>
          </div>
          <div class="staff-list">
            <el-scrollbar height="320px">
              <div class="serve-list">
                <template :key="item" v-for="item in serveList">
                  <div @click="handleServeClick(item)" class="item">
                    <div class="left">
                      <div class="name">{{ item.serveName }}</div>
                    </div>
                    <div class="right">
                      <el-checkbox-group
                        v-if="item.paraList.length > 0"
                        v-model="checkList"
                      >
                        <template :key="ele" v-for="ele in item.paraList">
                          <div @click="handleCheckbox(ele)">
                            <el-checkbox
                              @change="handleCheckBoxChange"
                              :label="ele.paraId"
                            >
                              <div class="checkbox">
                                <div class="salePrice">¥{{ ele.salePrice / 100 }}</div>
                                <div>{{ ele.serveTimeLength }}分钟</div>
                              </div>
                            </el-checkbox>
                          </div>
                        </template>
                      </el-checkbox-group>
                    </div>
                  </div>
                </template>
              </div>
            </el-scrollbar>
          </div>
          <!-- <el-pagination
            v-model:currentPage="currentPage.page"
            v-model:page-size="currentPage.pageSize"
            :page-sizes="[10, 20, 30, 40]"
            :small="true"
            layout=" sizes, prev, pager, next"
            :total="serveCount"
            :pager-count="3"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          /> -->
        </div>
        <div class="right-item">
          <div class="title">
            <div>已选服务</div>
            <div>售价</div>
            <div>可用次数</div>
            <div>操作</div>
          </div>
          <div class="opt-staff-list">
            <template :key="item" v-for="item in checkServeList">
              <template :key="ele" v-for="ele in item.paraIdList">
                <optServe
                  @removeServeClick="removeServeClick"
                  :serveName="item.serveName"
                  :ruleForm="ele"
                ></optServe>
              </template>
            </template>
          </div>
        </div>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, onUnmounted } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { useState } from '@/hooks'
import { UPLOAD_BASE_URL } from '@/common/constant'
import { nextTick } from 'process'
import optServe from './cpns/opt-serve/opt-serve.vue'
import emitter from '@/utils/eventbus'
import { deepClone } from '@/utils/clone'
import { getServeCategoryList, getServeList2 } from '@/service/main/serve'
import { useRoute } from 'vue-router'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PageDialog,
    optServe
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const route = useRoute()
    const store = useStore()
    const currentCrdId = computed(() => route.query.cardId)
    const ruleForm = reactive({
      name: ''
    })
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }
    watch(currentCrdId, () => {
      initPage()
    })

    const currentPage = reactive({
      page: 1,
      pageSize: 10
    })

    const handleSizeChange = (pageSize) => {
      currentPage.pageSize = pageSize
    }

    const handleCurrentChange = (page) => {
      currentPage.page = page
    }

    watch(currentPage, () => {
      initPage()
    }, {
      deep: true
    })

    const initPage =async () => {
      const res = await getServeCategoryList(COMPANY_ID)
      serveCategoryList.value = res.data.list
      getServeFunc()

      store.dispatch('card/getCardServeListAction', [currentCrdId.value, COMPANY_ID])

    }
    initPage()

    const serveList = ref([])
    const serveCount = computed(() => store.state.serve.serveCount)
    const cardServeList = computed(() => store.state.card.cardServeList)


    const handleRemoveClick = (item) => {

    }

    const handleSaveClick = () => {

    }

    const checkList = ref([])

    const queryInfo = reactive({
      kind: "",
      keyword: ""
    })

    const handleServeClick = (item) => {
      currentOptServe.serveName = item.serveName
    }

    const currentOptServe = reactive({
      serveId: '',
      flag: false,
      paraId: '',
      serveName: ''
    })

    const handleCheckBoxChange = (value) => {
      currentOptServe.flag = value
    }

    const serveCategoryList = ref([])
    const categoryChange = value => {
      getServeFunc()
    }

    const handleSearchClick = () => {
      getServeFunc()
    }

    const getServeFunc = async () => {
      const serveRes = await getServeList2({ ...queryInfo })
      let list = serveRes.data.servelist.filter(item=>{ return item.para})
      list.forEach(ele => {
        ele.para['serveId']=ele.serveId
        ele['paraList'] = [ele.para]
      })
      serveList.value = list
    }


    watch(cardServeList, (n) => {
      checkServeList.value = []
      checkList.value = []
      if (n != null) {
        n.forEach(item => {
          currentOptServe.flag = true
          currentOptServe.serveName = item.serveName
          handleCheckbox2(item)
          checkList.value.push(item.paraId)
        })
      }
    }, {
      deep: false
    })


    const checkServeList = ref([])
    const handleCheckbox = (item) => {
      nextTick(() => {
        const index = checkServeList.value.findIndex(ele => ele.serveId == item.serveId)
        if (index == -1) {
          item.Qty = 1
          item.salePrice = (item.salePrice)
          checkServeList.value.push({
            serveName: currentOptServe.serveName,
            serveId: item.serveId,
            paraIdList: [{ ...item, salePrice: (item.salePrice) / 100 }]
          })

        } else {
          if (currentOptServe.flag) {
            const indey = checkServeList.value[index].paraIdList.findIndex(ele => ele.paraId == item.paraId)
            if (indey == -1) {
              item.Qty = 1
              checkServeList.value[index].paraIdList.push({ ...item, salePrice: (item.salePrice) / 100 })
            }

          } else {
            let removeIndex = checkServeList.value[index].paraIdList.findIndex(val => val.paraId == item.paraId)
            checkServeList.value[index].paraIdList.splice(removeIndex, 1)
          }
        }
      })
    }

    const handleCheckbox2 = (item) => {
      const index = checkServeList.value.findIndex(ele => ele.serveId == item.serveId)
      if (index == -1) {
        item.Qty = item.qty
        item.salePrice = (item.salePrice) / 100
        checkServeList.value.push({
          serveName: currentOptServe.serveName,
          serveId: item.serveId,
          paraIdList: [{ ...item, isUpdate: true }]
        })

      } else {
        if (currentOptServe.flag) {
          const indey = checkServeList.value[index].paraIdList.findIndex(ele => ele.paraId == item.paraId)
          if (indey == -1) {
            checkServeList.value[index].paraIdList.push(item)
          }

        } else {
          let removeIndex = checkServeList.value[index].paraIdList.findIndex(val => val.paraId == item.paraId)
          checkServeList.value[index].paraIdList.splice(removeIndex, 1)
        }
      }
    }

    const removeServeClick = (serveId, paraId) => {
      let index = checkServeList.value.findIndex(item => item.serveId == serveId)
      let removeIndex = checkServeList.value[index].paraIdList.findIndex(ele => ele.paraId == paraId)
      let removeCheckIndex = checkList.value.findIndex(ele => ele == paraId)
      checkServeList.value[index].paraIdList.splice(removeIndex, 1)
      checkList.value.splice(removeCheckIndex, 1)
    }

    watch(checkServeList, (n) => {
      emitter.emit('changeUpdateCurretnOptServeList', [...n])
    }, {
      deep: true
    })

    watch(() => route.path, (n) => {
      if (n == '/main/card/update') {
        checkList.value = []
        checkServeList.value = []
      }
    }, {
      deep: true
    })

    emitter.on('refreshUpdateCardPageAddserveDialog', (n) => {
      checkServeList.value = n
      checkList.value = []
      let serveid = []
      checkServeList.value.forEach(item => {
        let paraIdList = item.paraIdList
        paraIdList.forEach(ele => {
          serveid.push(ele.paraId)
        })
      })
      checkList.value = serveid
    })

    onUnmounted(() => {
      emitter.off('refreshUpdateCardPageAddserveDialog')
    })


    return {
      handleSearchClick,
      categoryChange,
      serveCategoryList,
      queryInfo,
      handleCheckbox2,
      cardServeList,
      currentOptServe,
      handleCheckbox,
      checkServeList,
      handleServeClick,
      checkList,
      removeServeClick,
      serveCount,
      serveList,
      changeDialogVisible,
      ruleForm,
      COMPANY_ID,
      UPLOAD_BASE_URL,
      handleCheckBoxChange,
      handleRemoveClick,
      handleSaveClick,
      currentPage,
      handleSizeChange,
      handleCurrentChange,
      currentCrdId

    }

  }
})
</script>

<style scoped lang="less">
.add-staff-dialog {
  &:deep(.el-select) {
    width: 100%;
  }
  .top-form {
    width: 50%;
    display: flex;
    &:deep(.el-button) {
      margin-left: 10px;
    }

    &:deep(.el-select) {
      margin-right: 10px;
    }

    .search {
      display: flex;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  &:deep(.is-horizontal) {
    display: none !important;
  }

  .content {
    display: flex;
    border-top: 1px solid var(--borderColor);
    padding-top: 20px;

    .left-item {
      flex: 1;

      .titls {
        display: flex;
        margin-bottom: 20px;
        padding: 0 10px;
        background-color: rgb(239, 239, 239);
        font-size: 12px;

        div {
          flex: 1;
          line-height: 40px;
        }
      }

      .staff-list {
        margin-top: 20px;
        padding: 0 10px;

        .serve-list {
          .item {
            display: flex;
            font-size: 12px;

            &:deep(.el-checkbox) {
              display: inline-block;
              width: 100% !important;

              .el-checkbox__label {
                display: inline-block;
                width: 100%;
              }
            }

            .left {
              flex: 1;
            }

            .right {
              flex: 2;

              .checkbox {
                display: flex;

                div {
                  flex: 1;
                }

                .salePrice {
                  color: rgb(255, 105, 74);
                }
              }
            }
          }
        }

        &:deep(.el-checkbox-group) {
          .el-checkbox-button {
            width: 100%;
            margin-bottom: 10px;

            span {
              width: 100%;
            }
          }
        }
      }
    }

    .right-item {
      margin-left: 10px;
      padding-left: 10px;
      flex: 1;
      border-left: 1px solid var(--borderColor);

      .title {
        padding: 0 10px;
        display: flex;
        background-color: rgb(239, 239, 239);

        div {
          flex: 1;
          padding-left: 4px;
          line-height: 40px;
        }
      }
    }
  }
}
</style>
